import React from 'react';
import { graphql } from 'gatsby'

import Layout from '../Layout';

export default function Page({data}) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return (
      <Layout fullMenu>
        <section id="wrapper">
          <header>
            <div className="inner">
            <h2>{frontmatter.title}</h2>
              <p>
                {frontmatter.subtitle}
              </p>
            </div>
          </header>

          <div className="wrapper">
            <div className="inner">
                <h2 className="major">{frontmatter.description}</h2>
                <div 
                    className="markdown-content" 
                    dangerouslySetInnerHTML={{__html: html}}
                />
            </div>
          </div>
        </section>
      </Layout>
    );
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } } ) {
            html
            frontmatter {
                path
                title
                subtitle
                description
            }
        }
    }
`
